import { template as template_9e03fb76690244d4b05133eb41712b3b } from "@ember/template-compiler";
const FKLabel = template_9e03fb76690244d4b05133eb41712b3b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
