import { template as template_8c37cc8117a14318b7a4cd917ab19800 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8c37cc8117a14318b7a4cd917ab19800(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
