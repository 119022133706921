import { template as template_05e6aa6e570e46419d31c81c37a2a00e } from "@ember/template-compiler";
const SidebarSectionMessage = template_05e6aa6e570e46419d31c81c37a2a00e(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
